import React from 'react';
import { useTranslation } from 'react-i18next';
import IconParagraph from '../IconParagraph';

const EuGoals = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <div className="stack">
      <h2>{tHelpAndSupport('commonQuestions.GoalAchievement.EU')}</h2>
      <IconParagraph header={tHelpAndSupport('commonQuestions.GoalAchievement.EU.1')}>
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.EU.1.Text')}</p>
      </IconParagraph>
      <IconParagraph header={tHelpAndSupport('commonQuestions.GoalAchievement.EU.2')}>
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.EU.2.Text')}</p>
      </IconParagraph>
      <IconParagraph header={tHelpAndSupport('commonQuestions.GoalAchievement.EU.3')}>
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.EU.3.Text')}</p>
      </IconParagraph>
      <IconParagraph header={tHelpAndSupport('commonQuestions.GoalAchievement.EU.4')}>
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.EU.4.Text')}</p>
      </IconParagraph>
      <IconParagraph header={tHelpAndSupport('commonQuestions.GoalAchievement.EU.5')}>
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.EU.5.Text')}</p>
      </IconParagraph>
      <IconParagraph header={tHelpAndSupport('commonQuestions.GoalAchievement.EU.6')}>
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.EU.6.Text')}</p>
      </IconParagraph>
    </div>
  );
};

export default EuGoals;
