import React from 'react';
import { SidemenuPaths } from '../drawer-sidemenu/routes';
import { icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Button, FaIcon } from '@in/component-library';
import './OpenMenuInfoButton.scss';
import useSiteimprove from 'src/hooks/use-siteimprove';
import { useLocation } from 'react-router-dom';

type Props = {
  page: SidemenuPaths;
  smallIcon?: boolean;
};

export const OpenMenuInfoButton: React.FC<Props> = ({ page, smallIcon }) => {
  const siteimprove = useSiteimprove();
  const location = useLocation();

  return (
    <Button
      className={'open-menu-info-button'}
      aria-label="Åpne hjelp og brukerstøtte"
      theme="none"
      variant="outlined"
      size="small"
      onClick={() => {
        siteimprove?.log('OPEN_HELP_MENU', page, location.pathname);
        window.dispatchEvent(
          new CustomEvent('openSideMenu', {
            detail: page,
          }),
        );
      }}
    >
      <FaIcon
        icon={icon({ prefix: 'fas', iconName: 'circle-question' }) as IconDefinition}
        className={smallIcon ? 'open-menu-info-button__icon-small' : 'open-menu-info-button__icon'}
      />
    </Button>
  );
};

export default OpenMenuInfoButton;
