import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SideMenuSubpageContext } from 'src/features/onboarding/constants/Context';
import SideMenuNavButton from '../../drawer-sidemenu/SideMenuNavButton';
import { useAuth } from 'src/features/authorization';

const Index = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const { setPage } = useContext(SideMenuSubpageContext);
  const { checkAccess } = useAuth();
  const { read: isBusinessGarden } = checkAccess('UI_SivaBusinessGarden');

  return (
    <>
      <h2>
        {isBusinessGarden
          ? tHelpAndSupport('commonQuestions.TargetCompanies')
          : tHelpAndSupport('commonQuestions.IdeasAndCompanies')}
      </h2>
      <div>
        <SideMenuNavButton
          onClick={() => setPage && setPage('index/commonQuestions/ideasAndCompanies/customerSatisfaction')}
        >
          {tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction')}
        </SideMenuNavButton>
        <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/ideasAndCompanies/esa')}>
          {tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa')}
        </SideMenuNavButton>
        {!isBusinessGarden && (
          <>
            <SideMenuNavButton
              onClick={() => setPage && setPage('index/commonQuestions/ideasAndCompanies/revertPhase')}
            >
              {tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase')}
            </SideMenuNavButton>
            <SideMenuNavButton
              onClick={() => setPage && setPage('index/commonQuestions/ideasAndCompanies/batchClose')}
            >
              {tHelpAndSupport('commonQuestions.IdeasAndCompanies.BatchClose')}
            </SideMenuNavButton>
          </>
        )}
        <SideMenuNavButton
          onClick={() => setPage && setPage('index/commonQuestions/ideasAndCompanies/reOpen')}
        >
          {`${isBusinessGarden ? tHelpAndSupport('commonQuestions.TargetCompanies.Reopen') : tHelpAndSupport('commonQuestions.IdeasAndCompanies.Reopen')}`}
        </SideMenuNavButton>
      </div>
    </>
  );
};

export default Index;
