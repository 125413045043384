import React, { FC, PropsWithChildren } from 'react';
import css from './PageWithSideComponents.module.scss';

export interface PageProps {
  leftComponent?: React.ReactNode;
  children: React.ReactNode;
}

const PageWithSideComponents: FC<PageProps> = ({ leftComponent, children }: PropsWithChildren<PageProps>) => {
  return (
    <main className={`${css['sidebar-layout']} with-sidebar`}>
      {leftComponent && leftComponent}
      {children}
    </main>
  );
};
export default PageWithSideComponents;
