import React from 'react';
import { useTranslation } from 'react-i18next';
import IconParagraph from '../IconParagraph';

const Srl = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <div className="stack">
      <h2>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL')}</h2>
      <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.Ingress')}</p>
      <IconParagraph header="SRL 9">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.9')}</p>
      </IconParagraph>
      <IconParagraph header="SRL 8">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.8')}</p>
      </IconParagraph>
      <IconParagraph header="SRL 7">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.7')}</p>
      </IconParagraph>
      <IconParagraph header="SRL 6">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.6')}</p>
      </IconParagraph>
      <IconParagraph header="SRL 5">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.5')}</p>
      </IconParagraph>
      <IconParagraph header="SRL 4">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.4')}</p>
      </IconParagraph>
      <IconParagraph header="SRL 3">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.3')}</p>
      </IconParagraph>
      <IconParagraph header="SRL 2">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.2')}</p>
      </IconParagraph>
      <IconParagraph header="SRL 1">
        <p>{tHelpAndSupport('commonQuestions.GoalAchievement.SRL.1')}</p>
      </IconParagraph>
    </div>
  );
};

export default Srl;
