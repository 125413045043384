import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  BatchArchiveOpportunityDto,
  CreateSharedStatusDto,
  OneWayInOpportunityStatusCreateDto,
  OneWayInRecommendation,
  OneWayInService,
} from 'src/api/v2';

import { useTranslation } from 'react-i18next';
import {
  ONE_WAY_IN_ALL_FORMS_KEY,
  ONE_WAY_IN_ALL_SAVED_FORMS_KEY,
  ONE_WAY_IN_FORM_FULL_HISTORY,
  ONE_WAY_IN_FORM_IN_RESPONSE_MAIL,
  ONE_WAY_IN_GROUPED_OPPORTUNITY_FORM,
} from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';

const useOneWayIn = (formId: string | undefined = '') => {
  const [oneWayInService] = useState(new OneWayInService());
  const { t: tCommon } = useTranslation('common');

  const { checkAccess } = useAuth();
  const { read, write } = checkAccess('OneWayIn');

  const queryClient = useQueryClient();

  const oneWayInOpportunityFormQuery = useQuery(
    [ONE_WAY_IN_GROUPED_OPPORTUNITY_FORM, formId],
    () => oneWayInService.getOpportunityForm(formId),
    {
      enabled: !!formId && read,
      retry: false,
      staleTime: 1000 * 60 * 15, // 15 min refetching on mount
      refetchOnMount: true,
      keepPreviousData: false,
    },
  );

  const oneWayInOpportunityFormFullHistoryQuery = useQuery(
    [ONE_WAY_IN_FORM_FULL_HISTORY, formId],
    () => oneWayInService.getOneWayInOpportunityFullHistory(formId),
    {
      enabled: !!formId && !!oneWayInOpportunityFormQuery.data && read,
      retry: false,
      staleTime: 1000 * 60 * 15, // 15 min refetching on mount
      refetchOnMount: true,
      keepPreviousData: false,
    },
  );

  const onewWayInOpportunityFormINResponseMailQuery = useQuery(
    [ONE_WAY_IN_FORM_IN_RESPONSE_MAIL, formId],
    () => {
      return oneWayInService.getInquiryINResponse(formId);
    },
    {
      enabled: !!formId && !!oneWayInOpportunityFormQuery.data && read,
      retry: false,
      refetchOnMount: false,
      keepPreviousData: false,
    },
  );

  const updateOneWayInOpportunityStatus = useMutation(
    (updateDto: OneWayInOpportunityStatusCreateDto) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return oneWayInService.putWayInOpportunityStatus(updateDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ONE_WAY_IN_FORM_FULL_HISTORY, formId]);
        queryClient.invalidateQueries([ONE_WAY_IN_ALL_SAVED_FORMS_KEY]);
        return queryClient.invalidateQueries([ONE_WAY_IN_GROUPED_OPPORTUNITY_FORM, formId]);
      },
    },
  );

  const batchArchiveOneWayInOpportunity = useMutation(
    (batchArchiveOpportunityDto: BatchArchiveOpportunityDto) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return oneWayInService.archiveOneWayInOpportunity(batchArchiveOpportunityDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ONE_WAY_IN_FORM_FULL_HISTORY, formId]);
        queryClient.invalidateQueries([ONE_WAY_IN_ALL_SAVED_FORMS_KEY]);
        return queryClient.invalidateQueries([ONE_WAY_IN_GROUPED_OPPORTUNITY_FORM, formId]);
      },
    },
  );

  const toggleCloseOneWayInOpportunity = useMutation(
    (updateDto: OneWayInOpportunityStatusCreateDto) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }
      return oneWayInService.putWayInOpportunityStatus(updateDto);
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([ONE_WAY_IN_FORM_FULL_HISTORY, formId]);
        queryClient.resetQueries([ONE_WAY_IN_GROUPED_OPPORTUNITY_FORM, formId]);
        queryClient.invalidateQueries([ONE_WAY_IN_ALL_FORMS_KEY]);
        queryClient.invalidateQueries([ONE_WAY_IN_ALL_SAVED_FORMS_KEY]);
      },
    },
  );

  const shareOneWayInOpportunity = useMutation(
    ({ createDto, opportunityId }: { createDto: CreateSharedStatusDto; opportunityId: string }) => {
      if (!write || !formId) {
        throw new Error(tCommon('cannotWrite'));
      }

      return oneWayInService.shareOpportunity(opportunityId, createDto);
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([ONE_WAY_IN_FORM_FULL_HISTORY, formId]);
        oneWayInOpportunityFormQuery.refetch();
        queryClient.invalidateQueries([ONE_WAY_IN_ALL_FORMS_KEY]);
        queryClient.invalidateQueries([ONE_WAY_IN_ALL_SAVED_FORMS_KEY]);
      },
    },
  );

  const recommendService = useMutation(
    (recommendation: OneWayInRecommendation) => {
      if (!write || !formId) {
        throw new Error(tCommon('cannotWrite'));
      }
      return oneWayInService.recommendServices(recommendation);
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([ONE_WAY_IN_FORM_FULL_HISTORY, formId]);
        oneWayInOpportunityFormQuery.refetch();
        queryClient.invalidateQueries([ONE_WAY_IN_ALL_FORMS_KEY]);
        queryClient.invalidateQueries([ONE_WAY_IN_ALL_SAVED_FORMS_KEY]);
      },
    },
  );

  return {
    oneWayInOpportunityFormQuery,
    updateOneWayInOpportunityStatus,
    oneWayInOpportunityFormFullHistoryQuery,
    onewWayInOpportunityFormINResponseMailQuery,
    shareOneWayInOpportunity,
    recommendService,
    toggleCloseOneWayInOpportunity,
    batchArchiveOneWayInOpportunity,
  };
};

export default useOneWayIn;
