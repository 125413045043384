import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SideMenuSubpageContext } from '../../../constants/Context';
import SideMenuNavButton from '../../drawer-sidemenu/SideMenuNavButton';

const GoalAchievement = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const { setPage } = useContext(SideMenuSubpageContext);

  return (
    <>
      <h2>{tHelpAndSupport('commonQuestions.GoalAchievement.Header')}</h2>
      <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/goalAchievement/delivery')}>
        {tHelpAndSupport('commonQuestions.GoalAchievement.Delivery')}
      </SideMenuNavButton>
      <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/goalAchievement/update')}>
        {tHelpAndSupport('commonQuestions.GoalAchievement.Update')}
      </SideMenuNavButton>
      <p className="font-weight--bold">Bærekraft</p>
      <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/goalAchievement/srl')}>
        {tHelpAndSupport('commonQuestions.GoalAchievement.SRL')}
      </SideMenuNavButton>
      <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/goalAchievement/eu')}>
        {tHelpAndSupport('commonQuestions.GoalAchievement.EU')}
      </SideMenuNavButton>
    </>
  );
};

export default GoalAchievement;
