import React, { useCallback, useEffect, useContext } from 'react';

import './DrawerSideMenu.scss';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { SideMenuSubpageContext } from '../../constants/Context';
import { FaIcon, FaIcons } from '@in/component-library';
import { SidemenuPaths } from './routes';

type Props = {
  minimized?: boolean;
};

const DrawerSideMenu: React.FC<React.PropsWithChildren<Props>> = ({ minimized, children }) => {
  const { setPage } = useContext(SideMenuSubpageContext);
  const [localStorageMinimized, setLocalStorageMinimized] = useLocalStorage(
    'drawerSideMenuMinimization',
    minimized || false,
  );

  const closeMenu = useCallback(() => {
    setLocalStorageMinimized(false);
  }, [setLocalStorageMinimized]);

  const openSideMenu = useCallback(
    (e: CustomEventInit<SidemenuPaths>) => {
      if (setPage) setPage(e.detail!);
      setLocalStorageMinimized(false);
    },
    [setLocalStorageMinimized, setPage],
  );

  //TODO https://developer.mozilla.org/en-US/docs/Web/API/Invoker_Commands_API
  //Når dette forhåpentligvis er baseline, så vil dette forenkle sidemenyen betraktelig
  useEffect(() => {
    window.addEventListener('openDrawerMenu', closeMenu);
    window.addEventListener('openSideMenu', openSideMenu);
    return () => {
      window.removeEventListener('openDrawerMenu', closeMenu);
      window.removeEventListener('openSideMenu', openSideMenu);
    };
  }, [closeMenu, setLocalStorageMinimized, openSideMenu]);

  return (
    <aside className="display--flex padding--0 drawer__wrapper">
      <button
        onClick={() => setLocalStorageMinimized(!localStorageMinimized)}
        className=" drawer-side-menu__menu--handle"
      >
        {localStorageMinimized ? (
          <FaIcon name={FaIcons.AngleDoubleLeftLight} />
        ) : (
          <FaIcon name={FaIcons.AngleDoubleRightLight} />
        )}

        <FaIcon name={FaIcons.QuestionCircleLight} />
      </button>

      {!localStorageMinimized && (
        <div className="drawer-side-menu__menu stack">
          <>
            {React.Children.toArray(children)?.map((child) =>
              React.cloneElement(child as React.ReactElement<any>, { minimized: localStorageMinimized }),
            )}
          </>
        </div>
      )}
    </aside>
  );
};

export default DrawerSideMenu;
