import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SideMenuNavButton from '../drawer-sidemenu/SideMenuNavButton';
import { useAuth } from 'src/features/authorization';
import { SideMenuSubpageContext } from '../../constants/Context';

const CommonQuestions: React.FC = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const { t: tCommon } = useTranslation();
  const { setPage } = useContext(SideMenuSubpageContext);
  const { checkAccess } = useAuth();
  const isSiva = checkAccess('SivaCore').read;
  const { read: isBusinessGarden } = checkAccess('UI_SivaBusinessGarden');

  return (
    <>
      <h2>{tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}</h2>
      <div>
        <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/owi')}>
          {tCommon('oneWayIn')}
        </SideMenuNavButton>
        {isSiva && (
          <>
            <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/ideasAndCompanies')}>
              {isBusinessGarden
                ? tHelpAndSupport('commonQuestions.TargetCompanies')
                : tHelpAndSupport('commonQuestions.IdeasAndCompanies')}
            </SideMenuNavButton>
            <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/sivaReporting')}>
              {tHelpAndSupport('commonQuestions.SivaReporting.Header')}
            </SideMenuNavButton>
            <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/budgets')}>
              {tHelpAndSupport('commonQuestions.SivaBudgets.Header')}
            </SideMenuNavButton>
            <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/goalAchievement')}>
              {tHelpAndSupport('commonQuestions.GoalAchievement.Header')}
            </SideMenuNavButton>
          </>
        )}
      </div>
    </>
  );
};

export default CommonQuestions;
