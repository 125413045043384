import React, { useState } from 'react';
import DrawerSideMenu from './drawer-sidemenu/DrawerSideMenu';
import Introduction from './Introduction';
import { useOnboarding } from 'src/features/onboarding/hooks.tsx/use-onboarding';

import { OnboardingPacketDto } from 'src/api/v2';
import { SideMenuSubpageContext } from '../constants/Context';
import { NavigationWrapper, renderPage, SidemenuPaths } from './drawer-sidemenu/routes';

const OnboardingSideMenu: React.FC = () => {
  const { priorityPacket } = useOnboarding();
  const [flow, setFlow] = useState<OnboardingPacketDto | null>(null);
  const [page, setPage] = useState<SidemenuPaths>('index');

  return (
    <SideMenuSubpageContext.Provider value={{ page, setPage, flow, setFlow }}>
      <DrawerSideMenu>
        {priorityPacket && <Introduction onboardingPacket={priorityPacket} />}
        <NavigationWrapper path={page}>{renderPage(page)}</NavigationWrapper>
      </DrawerSideMenu>
    </SideMenuSubpageContext.Provider>
  );
};

export default OnboardingSideMenu;
