import React from 'react';
import { useTranslation } from 'react-i18next';
import IconParagraph from '../IconParagraph';
import { FaIcons } from '@in/component-library';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/navigation';
import { useAuth } from 'src/features/authorization';

const Reopen = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const { checkAccess } = useAuth();
  const { read: isBusinessGarden } = checkAccess('UI_SivaBusinessGarden');

  const businessGardenContent = () => {
    return (
      <>
        <h2 className="margin-bottom--3">{tHelpAndSupport('commonQuestions.TargetCompanies.Reopen')}</h2>
        <IconParagraph header={tHelpAndSupport('commonQuestions.TargetCompanies.RevertTermination')}>
          <p>{tHelpAndSupport('commonQuestions.TargetCompanies.Reopen.Text1')}</p>
          <p>{tHelpAndSupport('commonQuestions.TargetCompanies.Reopen.Text2')}</p>
          <p>{tHelpAndSupport('commonQuestions.TargetCompanies.Reopen.Text3')}</p>
        </IconParagraph>
        <IconParagraph
          faIcon={FaIcons.CalendarAltLight}
          header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.Reopen.KTU')}
        >
          <p>{tHelpAndSupport('commonQuestions.TargetCompanies.Reopen.KTU.Text')}</p>
        </IconParagraph>
      </>
    );
  };

  const incubatorContent = () => {
    return (
      <>
        <h2 className="margin-bottom--3">{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Reopen')}</h2>
        <IconParagraph header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase')}>
          <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Reopen.Text1')}</p>
          <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Reopen.Text2')}</p>
          <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Reopen.Text3')}</p>
        </IconParagraph>
        <IconParagraph
          faIcon={FaIcons.CalendarAltLight}
          header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.Reopen.KTU')}
        >
          <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Reopen.KTU.Text')}</p>
        </IconParagraph>
      </>
    );
  };

  return (
    <div className="stack">
      {isBusinessGarden ? businessGardenContent() : incubatorContent()}
      <Link to={ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE} className="display--block">
        {tHelpAndSupport('commonQuestions.IdeasAndCompanies.Link')}
      </Link>
    </div>
  );
};

export default Reopen;
