import classNames from 'classnames';
import React, { FC } from 'react';
import SideActionMenu from 'src/components/Layout/SideActionMenu/SideActionMenu';
import { useUser } from 'src/features/authorization';
import AppHeader from '../AppHeader';
import ProgramRenderer from '../ProgramRenderer/ProgramRenderer';
import './Layout.scss';
import useMaintenance from 'src/features/maintenance/hooks/use-maintenance';
import MaintenanceBanner from 'src/features/maintenance/components/MaintenanceBanner';
import DisplayFooter from '../Footer/DisplayFooter';
import { OnboardingSideMenu } from 'src/features/onboarding';

type Props = {
  user: ReturnType<typeof useUser>;
};

export const Layout: FC<React.PropsWithChildren<Props>> = ({ user, children }) => {
  const { isUserLoggedIn, isUserRegistered, loadingUser, chosenClusterId, b2cInfo } = user;
  const { isMaintenanceActive } = useMaintenance();

  const classes = classNames('layout-wrapper', {
    isLoggedOutMaintenance: (isUserLoggedIn === false || isUserRegistered === false) && isMaintenanceActive,
    isLoggedOut: isUserLoggedIn === false || isUserRegistered === false,
    isLoggedIn: isUserLoggedIn,
    isPending: loadingUser || b2cInfo.isLoading,
  });

  return (
    <div className={classes}>
      <AppHeader />
      <MaintenanceBanner />
      <div className="layout__body-wrapper">
        <div className="layout-wrapper__main">{children}</div>
        {isUserLoggedIn && chosenClusterId && (
          <ProgramRenderer
            render={[
              {
                program: '*',
                children: <SideActionMenu className="side-action-menu" />,
              },
              {
                program: 'siva',
                children: <OnboardingSideMenu />,
              },
              {
                program: 'one-way-in',
                children: <OnboardingSideMenu />,
              },
            ]}
          />
        )}
      </div>

      {isUserLoggedIn && <DisplayFooter />}
    </div>
  );
};
