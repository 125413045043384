import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/navigation';

const Update = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <div className="stack">
      <h2>{tHelpAndSupport('commonQuestions.GoalAchievement.Update')}</h2>
      <p>{tHelpAndSupport('commonQuestions.GoalAchievement.Update.Text1')}</p>
      <p>{tHelpAndSupport('commonQuestions.GoalAchievement.Update.Text2')}</p>
      <Link className="display--block" to={ROUTES.SIVA.REPORTING.ACHIEVED.BASE}>
        {tHelpAndSupport('commonQuestions.SivaReporting.GoalAchievement.Link')}
      </Link>
    </div>
  );
};

export default Update;
