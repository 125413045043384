import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SideMenuSubpageContext } from 'src/features/onboarding/constants/Context';
import SideMenuNavButton from '../../drawer-sidemenu/SideMenuNavButton';

const Index = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const { setPage } = useContext(SideMenuSubpageContext);

  return (
    <>
      <h2>{tHelpAndSupport('commonQuestions.IdeasAndCompanies')}</h2>
      <div>
        <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/owi/actorCommunication')}>
          {tHelpAndSupport('commonQuestions.Interplay.CommunicationBetweenActors')}
        </SideMenuNavButton>
        <SideMenuNavButton onClick={() => setPage && setPage('index/commonQuestions/owi/referralProcess')}>
          {tHelpAndSupport('commonQuestions.Interplay.Referralprocess')}
        </SideMenuNavButton>
      </div>
    </>
  );
};

export default Index;
