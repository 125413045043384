import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  ProjectActivitiesService,
  ProjectExpensesService,
  ProjectExpenseUpdateDto,
  ProjectFinanceListUpdateDto,
  ProjectFinancesService,
  ProjectMembersService,
} from 'src/api/v2';

import { ClusterMemberCategories } from 'src/constants/cluster-member-category';
import {
  PROJECT_DETAIL_KEY,
  PROJECT_DETAIL_FINANCES_KEY,
  PROJECT_DETAIL_ACITIVITES_KEY,
  PROJECT_DETAIL_MEMBERS_KEY,
  PROJECT_DETAIL_EXPENSES_KEY,
  PROJECTS_KEY,
} from 'src/constants/hook-keys';

import useCluster from '../features/cluster/hooks/use-cluster';
import useTimeEntries from './use-time-entries';

export default function useProjectDetails(projectId: string | undefined) {
  const { cluster } = useCluster();
  const { timeEntries } = useTimeEntries();

  const queryClient = useQueryClient();

  const [projectMembersService] = useState(new ProjectMembersService());
  const [projectFinancesService] = useState(new ProjectFinancesService());
  const [projectExpensesService] = useState(new ProjectExpensesService());
  const [projectActivitiesService] = useState(new ProjectActivitiesService());

  const projectFinancesQuery = useQuery(
    [PROJECT_DETAIL_FINANCES_KEY, projectId],
    () => projectFinancesService.getProjectFinanceListForProject(projectId!),
    {
      enabled: !!cluster && !!projectId,
    },
  );

  const projectActivitiesQuery = useQuery(
    [PROJECT_DETAIL_ACITIVITES_KEY, projectId],
    () => projectActivitiesService.getProjectActivities(projectId!),
    {
      enabled: !!cluster && !!projectId,
    },
  );

  const projectMembersQuery = useQuery(
    [PROJECT_DETAIL_MEMBERS_KEY, projectId],
    () => projectMembersService.getProjectMemberList(projectId!),
    {
      enabled: !!cluster && !!projectId,
    },
  );

  const projectExpensesQuery = useQuery(
    [PROJECT_DETAIL_EXPENSES_KEY, projectId],
    () => projectExpensesService.getExpensesForProject(projectId!),
    {
      enabled: !!cluster && !!projectId,
    },
  );

  const projectTimeEntries = useMemo(
    () => timeEntries.filter((x) => x.projectId === projectId),
    [projectId, timeEntries],
  );

  const projectMembers = useMemo(
    () =>
      projectMembersQuery.data
        ?.filter(
          (x) =>
            x.clusterMember.clusterMemberCategory?.code === ClusterMemberCategories.Member.code ||
            x.clusterMember.clusterMemberCategory?.code === ClusterMemberCategories.CoreMember.code ||
            x.clusterMember.clusterMemberCategory?.code === ClusterMemberCategories.AssociateMember.code,
        )
        .map((x) => x.clusterMember),
    [projectMembersQuery.data],
  );

  const projectLeads = useMemo(
    () =>
      projectMembersQuery.data
        ?.filter((x) => x.clusterMember.clusterMemberCategory?.code === ClusterMemberCategories.Lead.code)
        .map((x) => x.clusterMember),
    [projectMembersQuery.data],
  );

  const invalidateQueries = () => {
    queryClient.resetQueries([PROJECTS_KEY]);
    queryClient.invalidateQueries([PROJECT_DETAIL_KEY, projectId]);
    queryClient.invalidateQueries([PROJECT_DETAIL_FINANCES_KEY, projectId]);
    queryClient.invalidateQueries([PROJECT_DETAIL_ACITIVITES_KEY, projectId]);
    queryClient.invalidateQueries([PROJECT_DETAIL_MEMBERS_KEY, projectId]);
  };

  const updateExpensesMutation = useMutation(
    (records: ProjectExpenseUpdateDto[]) => {
      return projectExpensesService.updateProjectExpensesForProject(projectId!, records);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([PROJECT_DETAIL_EXPENSES_KEY, projectId]);
      },
    },
  );

  const updateFinancesMutation = useMutation(
    (records: ProjectFinanceListUpdateDto) => {
      return projectFinancesService.updateFinancesForProject(projectId!, records);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([PROJECT_DETAIL_FINANCES_KEY, projectId]);
      },
    },
  );

  return {
    timeEntries: projectTimeEntries || [],
    projectFinances: projectFinancesQuery.data || [],
    projectActivities: projectActivitiesQuery.data || [],
    projectMembers: projectMembers || [],
    projectLeads: projectLeads || [],
    projectExpenses: projectExpensesQuery.data || [],
    projectMembersQuery,

    invalidateQueries,

    mutations: {
      updateExpenses: updateExpensesMutation,
      updateFinances: updateFinancesMutation,
    },
  };
}
