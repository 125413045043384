import React from 'react';
import { useTranslation } from 'react-i18next';
import IconParagraph from '../IconParagraph';
import { FaIcons } from '@in/component-library';
import { ROUTES } from 'src/navigation';
import { Link } from 'react-router-dom';

const RevertPhase = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <div className="stack">
      <h2 className="margin-bottom--3">{tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase')}</h2>
      <IconParagraph
        iconDef={{ prefix: 'fal', iconName: 'arrow-rotate-left' }}
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase')}
      >
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase.Text1')}</p>
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase.Text2')}</p>
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase.Text3')}</p>
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase.Text4')}</p>
      </IconParagraph>
      <IconParagraph
        faIcon={FaIcons.EditLight}
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase.Edit')}
      >
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.RevertPhase.Edit.Text')}</p>
      </IconParagraph>
      <Link to={ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE} className="display--block">
        {tHelpAndSupport('commonQuestions.IdeasAndCompanies.Link')}
      </Link>
    </div>
  );
};

export default RevertPhase;
