import React from 'react';
import { FaIcons } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import IconParagraph from './IconParagraph';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/navigation';
import { useAuth } from 'src/features/authorization';

const SivaReporting = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const { checkAccess } = useAuth();
  const { read: isBusinessGarden } = checkAccess('UI_SivaBusinessGarden');

  return (
    <div className="stack">
      <h2 className="margin-bottom--3">{tHelpAndSupport('commonQuestions.SivaReporting.Header')}</h2>
      <p>{tHelpAndSupport('commonQuestions.SivaReporting.Ingress')}</p>
      <IconParagraph
        iconDef={{ prefix: 'fal', iconName: 'traffic-light' }}
        header={tHelpAndSupport('commonQuestions.SivaReporting.Status.Header')}
      >
        <p>{tHelpAndSupport('commonQuestions.SivaReporting.Status.Text')}</p>
      </IconParagraph>
      <Link to={`${ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE}`} className="display--block">
        {tHelpAndSupport('commonQuestions.IdeasAndCompanies.LinkCompany')}
      </Link>
      <IconParagraph
        faIcon={FaIcons.ListUnordered}
        header={tHelpAndSupport('commonQuestions.SivaReporting.SelfDeclaration.Header')}
      >
        <p>{tHelpAndSupport('commonQuestions.SivaReporting.SelfDeclaration.Text')}</p>
      </IconParagraph>
      <Link to={`${ROUTES.SIVA.REPORTING.DECLARATION.BASE}`} className="display--block">
        {tHelpAndSupport('commonQuestions.SivaReporting.SelfDeclaration.Link')}
      </Link>
      <IconParagraph
        faIcon={FaIcons.CheckCircleLight}
        header={tHelpAndSupport('commonQuestions.SivaReporting.Yearly.Header')}
      >
        <p>{tHelpAndSupport('commonQuestions.SivaReporting.Yearly.Text')}</p>
      </IconParagraph>
      {!isBusinessGarden && (
        <IconParagraph
          faIcon={FaIcons.FileSignatureLight}
          header={tHelpAndSupport('commonQuestions.SivaReporting.Contract.Header')}
        >
          <p>{tHelpAndSupport('commonQuestions.SivaReporting.Contract.Text')}</p>
        </IconParagraph>
      )}

      <IconParagraph
        faIcon={FaIcons.FileSpreadsheetLight}
        header={tHelpAndSupport('commonQuestions.SivaReporting.Esa.Header')}
      >
        <p>{tHelpAndSupport('commonQuestions.SivaReporting.Esa.Text')}</p>
      </IconParagraph>
      <Link className="display--block" to={ROUTES.SIVA.REPORTING.ESA.BASE}>
        {tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa.Link')}
      </Link>
      <IconParagraph
        iconDef={{ prefix: 'fal', iconName: 'chart-bar' }}
        header={tHelpAndSupport('commonQuestions.SivaReporting.GoalAchievement.Header')}
      >
        <p>{tHelpAndSupport('commonQuestions.SivaReporting.GoalAchievement.Text')}</p>
      </IconParagraph>
      <Link className="display--block" to={ROUTES.SIVA.REPORTING.ACHIEVED.BASE}>
        {tHelpAndSupport('commonQuestions.SivaReporting.GoalAchievement.Link')}
      </Link>
    </div>
  );
};

export default SivaReporting;
