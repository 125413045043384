/**
 * Gets the which year SIVA-actors are reporting for right now
 * @returns Reporting Year if it is reporting season right now
 */
export default function useSivaReportingYear() {
  const now = new Date();
  const month = now.getMonth();
  const year = now.getFullYear();

  const reportingMonths = [
    0, // Jan
    9, // Oct
    10, // Nov
    11, // Des
  ];

  if (!reportingMonths.includes(month)) {
    return null;
  }

  if (month == 0) {
    return year - 1;
  }

  return year;
}
