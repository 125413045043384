import React from 'react';
import { useTranslation } from 'react-i18next';
import IconParagraph from '../IconParagraph';

const CustomerSatisfaction = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <div className="stack">
      <h2>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction')}</h2>
      <IconParagraph
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction.SendDate')}
      >
        <div className="stack-small">
          <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction.SendDate.Text1')}</p>
          <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction.SendDate.Text2')}</p>
          <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction.SendDate.Text3')}</p>
          <div className="display--flex gap--2">
            <div>
              <span className="font-weight--bold display--block">Opptaks-/utgangsdato</span>
              <span className="display--block">Nov - Jan</span>
              <span className="display--block">Feb - April</span>
              <span className="display--block">Mai - Juli</span>
              <span className="display--block">Aug - Okt</span>
            </div>
            <div>
              <span className="font-weight--bold display--block">Oppstarts-/Exitskjema</span>
              <span className="display--block">Mars</span>
              <span className="display--block">Juni</span>
              <span className="display--block">September</span>
              <span className="display--block">Desember</span>
            </div>
          </div>
        </div>
      </IconParagraph>
      <IconParagraph
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction.Schedule')}
      >
        <p>
          {tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction.Schedule.Text1')}
          <ol>
            <li>28. februar</li>
            <li>31. mai</li>
            <li>31. august</li>
            <li>30. november</li>
          </ol>
        </p>
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.CustomerSatisfaction.Schedule.Text2')}</p>
      </IconParagraph>
    </div>
  );
};

export default CustomerSatisfaction;
