import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, FaIcon, FaIcons, TextArea } from '@in/component-library';
import { Notification } from '@in/component-library';
import { useUser } from 'src/features/authorization';
import { OneWayInStatus } from 'src/api/v2';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useOneWayIn from 'src/features/one-way-in/hooks/use-one-way-in';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { OneWayInListDto } from '../../types/oneWayInList';

interface BatchArchivingModalProps {
  showDialog: boolean;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  selectedRows: OneWayInListDto[];
}

const BatchArchivingModal = ({ showDialog, setShowDialog, selectedRows }: BatchArchivingModalProps) => {
  const [comment, setComment] = useState('');
  const { displayName, user, isAadUser } = useUser();
  const { batchArchiveOneWayInOpportunity } = useOneWayIn('');
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { t: tCommon } = useTranslation('common');

  const ToastContent = ({ archivedCount }: { archivedCount: number }) => {
    return (
      <div className="display--flex gap--2">
        <FaIcon className="color--in-green-200" name={FaIcons.CheckCircleLight} />
        <div>
          <div className="margin-bottom--0 font-size--large font-weight--bold color--in-black-100">
            {archivedCount > 1
              ? tOneWayIn('batchArchive.toast.title.plural', { count: archivedCount })
              : tOneWayIn('batchArchive.toast.title.singular', { count: archivedCount })}
          </div>
          <div className="color--in-black-100 font-size--medium">
            {archivedCount > 1
              ? tOneWayIn('batchArchive.toast.text.plural', { count: archivedCount })
              : tOneWayIn('batchArchive.toast.text.singular', { count: archivedCount })}
          </div>
        </div>
      </div>
    );
  };

  const archiveOpportunity = async (
    oneWayInId: string,
    municipality: string,
    title: string,
    customerName: string,
  ) => {
    await batchArchiveOneWayInOpportunity.mutateAsync({
      oneWayInId: oneWayInId,
      municipality: municipality,
      title: title,
      customerName: customerName,
      oneWayInOpportunityId: undefined,
      referenceClusterId: undefined,
      description: comment,
      status: OneWayInStatus.Closed,
      closedReason: undefined,
      registeredServices: [],
      recommendedServices: [],
      clusterUserId: isAadUser ? undefined : user?.clusterUserId,
      clusterContactId: undefined,
      handlersName: displayName,
      sendFollowUpReminderOn: undefined,
    });
  };

  const batchArchive = () => {
    const promises: Promise<void>[] = [];
    selectedRows.forEach((row) => {
      promises.push(
        archiveOpportunity(row.oneWayInId, row.municipality ?? '', row.title, row.customerName ?? ''),
      );
    });
    Promise.all(promises).then(() => {
      setShowDialog(false);
      setComment('');
      toast(<ToastContent archivedCount={selectedRows.length} />, {
        toastId: 'ArchiveSuccessToast',
        autoClose: false,
        position: 'bottom-center',
      });
    });
  };

  return (
    <DialogContainer
      title={tOneWayIn('batchArchive.modal.title')}
      show={showDialog}
      onClose={() => setShowDialog(false)}
    >
      <p>
        {selectedRows.length > 1
          ? tOneWayIn('batchArchive.modal.text.plural')
          : tOneWayIn('batchArchive.modal.text.singular')}
      </p>
      <TextArea
        label={`${tCommon('note')} *`}
        aria-required="true"
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        maxCount={1000}
        maxLength={1000}
      />
      <div className="display--flex flex-direction--column gap--2">
        <Notification type="error" visible={batchArchiveOneWayInOpportunity.isError}>
          <span>
            {tOneWayIn('batchArchive.notification.error.part1')}
            <b>{tOneWayIn('batchArchive.notification.error.error')}</b>
            {tOneWayIn('batchArchive.notification.error.part2')}
          </span>
        </Notification>
        <div className="display--flex gap--1">
          <Button
            disabled={comment.trim() === ''}
            onClick={() => batchArchive()}
            isLoading={batchArchiveOneWayInOpportunity.isLoading}
          >
            {tOneWayIn('batchArchive.archive')}
          </Button>
          <Button variant="outlined" onClick={() => setShowDialog(false)}>
            {tCommon('cancel')}
          </Button>
        </div>
      </div>
    </DialogContainer>
  );
};

export default BatchArchivingModal;
