export const SITEIMPROVE_DECLARATIONS = {
  CATEGORIES: {
    FORWARDOPPORTUNITY: 'ForwardOpportunity',
    CHANGELANGUAGE: 'ChangeLanguage',
  },
  ACTIONS: {
    SEND_SUCCESS: 'SuccessfullySent',
    SEND_FAILURE: 'FailedToSend',
    ABORT_BUTTON: 'AbortedByButton',
    ABORT_X: 'AbortedAsX',
    START_REFERRAL: 'StartedReferralProcess',
    GO_TO_PREVIEW: 'WentToPreviewStep',
    REFERRAL_SEND_ATTEMPT: 'ReferralSentAttempt',
    REFERRAL_SEND_SUCCESS: 'ReferralSuccessfullySent',
    REFERRAL_SEND_FAILURE: 'ReferralFailedToSend',
    ARCHIVAL_SEND_ATTEMPT: 'ArchivalSentAttempt',
    ARCHIVAL_SEND_SUCCESS: 'ArchivalSuccessfullySent',
    ARCHIVAL_SEND_FAILURE: 'ArchivalFailedToSend',
    BACK_TO_CHOOSE_ACTOR: 'WentBackToChooseActor',
    BACK_TO_WRITE_REFERRAL: 'WentBackToWriteReferral',
    CHANGE_TO_NB: 'ChangeToNb',
    CHANGE_TO_NN: 'ChangeToNn',
    CHANGE_TO_EN: 'ChangeToEn',
  },
};
