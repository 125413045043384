import React from 'react';
import IconParagraph from './IconParagraph';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/navigation';
import { Link } from 'react-router-dom';

const SivaBudgets = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <div className="stack">
      <h2 className="margin-bottom--3">{tHelpAndSupport('commonQuestions.SivaBudgets.Header')}</h2>
      <IconParagraph header={tHelpAndSupport('commonQuestions.SivaBudgets.Budget')}>
        <p>{tHelpAndSupport('commonQuestions.SivaBudgets.Budget.Deadline')}</p>
        <p>{tHelpAndSupport('commonQuestions.SivaBudgets.Budget.Text')}</p>
      </IconParagraph>
      <IconParagraph header={tHelpAndSupport('commonQuestions.SivaBudgets.AccountantConfirmation')}>
        <p>{tHelpAndSupport('commonQuestions.SivaBudgets.AccountantConfirmation.Deadline')}</p>
        <p>{tHelpAndSupport('commonQuestions.SivaBudgets.AccountantConfirmation.Text1')}</p>
        <p>{tHelpAndSupport('commonQuestions.SivaBudgets.AccountantConfirmation.Text2')}</p>
      </IconParagraph>
      <Link className="display--block" to={ROUTES.SIVA.REPORTING.BUDGET}>
        {tHelpAndSupport('commonQuestions.SivaBudgets.Link')}
      </Link>
    </div>
  );
};

export default SivaBudgets;
