import React, { useContext, useMemo } from 'react';
import CommonQuestions from '../common-questions/CommonQuestions';
import { GuidedTourWrapper } from '../guided-tour/GuidedTourWrapper';
import ContactUs from '../ContactUs';
import Referralprocess from '../common-questions/OWI/ReferralProcess';
import SivaReporting from '../common-questions/SivaReporting';
import HelpAndSupportMenu from '../HelpAndSupportMenu';
import { SideMenuSubpageContext } from '../../constants/Context';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';
import { FaIcon, FaIcons } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import SivaBudgets from '../common-questions/SivaBudgets';
import GoalAchievement from '../common-questions/Goals/GoalAchievement';
import Delivery from '../common-questions/Goals/Delivery';
import Update from '../common-questions/Goals/Update';
import Srl from '../common-questions/Goals/Srl';
import EuGoals from '../common-questions/Goals/EuGoals';
import CustomerSatisfaction from '../common-questions/IdeasAndCompanies/CustomerSatisfaction';
import Esa from '../common-questions/IdeasAndCompanies/Esa';
import RevertPhase from '../common-questions/IdeasAndCompanies/RevertPhase';
import BatchClose from '../common-questions/IdeasAndCompanies/BatchClose';
import Reopen from '../common-questions/IdeasAndCompanies/Reopen';
import CommunicationBetweenActors from '../common-questions/OWI/CommunicationBetweenActors';
import IdeasAndCompanies from '../common-questions/IdeasAndCompanies/Index';
import OWI from '../common-questions/OWI/Index';
import { useAuth } from 'src/features/authorization';

const sideMenuRoutePaths = {
  index: ['commonQuestions', 'stepConfirmation', 'contactUs'],
  commonQuestions: ['owi', 'sivaReporting', 'budgets', 'goalAchievement', 'ideasAndCompanies'],
  goalAchievement: ['delivery', 'update', 'srl', 'eu'],
  ideasAndCompanies: ['customerSatisfaction', 'esa', 'revertPhase', 'batchClose', 'reOpen'],
  owi: ['referralProcess', 'actorCommunication'],
} as const;

//We can't use React Router for a sub-component like this, but we can let it inspire us :)

type RoutePathKeys = keyof typeof sideMenuRoutePaths;
type RoutePathValues<K extends RoutePathKeys> = (typeof sideMenuRoutePaths)[K][number];

type Home = `index`;
type IndexPath = `index/${RoutePathValues<'index'>}`;

type CommonQuestionsPath = `index/commonQuestions/${RoutePathValues<'commonQuestions'>}`;

type GoalPaths = `index/commonQuestions/goalAchievement/${RoutePathValues<'goalAchievement'>}`;

type IAndCPaths = `index/commonQuestions/ideasAndCompanies/${RoutePathValues<'ideasAndCompanies'>}`;

type OWIPaths = `index/commonQuestions/owi/${RoutePathValues<'owi'>}`;

export type SidemenuPaths = Home | IndexPath | CommonQuestionsPath | GoalPaths | OWIPaths | IAndCPaths;

const BreadCrumb = ({ segments }: { segments: string[] }) => {
  const { setPage } = useContext(SideMenuSubpageContext);
  const { checkAccess } = useAuth();
  const { read: isBusinessGarden } = checkAccess('UI_SivaBusinessGarden');

  const breadCrumbs = useMemo(() => {
    return segments.map((__, index, allSegments) => {
      return allSegments.slice(0, index).join('/');
    });
  }, [segments]);
  return (
    <nav className="display--flex flex-wrap--wrap">
      {breadCrumbs.map((bread, index) => {
        if (bread === '') return <></>;
        return (
          <>
            {index > 1 && <span className="margin-top--auto margin-bottom--auto">{'>'}</span>}
            <TertiaryButton
              minimalPadding
              className=""
              onClick={() => setPage && setPage(bread as SidemenuPaths)}
              key={bread + index}
            >
              {getLabel(bread, isBusinessGarden)}
            </TertiaryButton>
          </>
        );
      })}
    </nav>
  );
};

export const NavigationWrapper = ({ children, path }: { children: React.ReactNode; path: SidemenuPaths }) => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <>
      <div className="display--flex align-items--center gap--2">
        <FaIcon name={FaIcons.QuestionCircleLight} size="2x" />
        <h1 className="help-and-support__header">{tHelpAndSupport('helpAndSupport.Header')}</h1>
      </div>
      <BreadCrumb segments={path.split('/')} />
      {children}
    </>
  );
};

const getSideMenuRoutes = (isBusinessGarden: boolean = false) => {
  return {
    index: {
      element: <HelpAndSupportMenu />,
      label: 'Hjem',
      children: {
        stepConfirmation: { element: <GuidedTourWrapper />, label: 'Onboarding' },
        commonQuestions: {
          element: <CommonQuestions />,
          label: 'Vanlige spørsmål',
          children: {
            owi: {
              element: <OWI />,
              label: 'Én vei inn',
              children: {
                referralProcess: {
                  element: <Referralprocess />,
                  label: 'Henvisningsprosess',
                },
                actorCommunication: {
                  element: <CommunicationBetweenActors />,
                  label: 'Kommunikasjon mellom aktører',
                },
              },
            },
            sivaReporting: { element: <SivaReporting />, label: 'Årsrapportering' },
            budgets: { element: <SivaBudgets />, label: 'Budsjett og Revisorbekreftelse' },
            goalAchievement: {
              element: <GoalAchievement />,
              label: 'Måltall og måloppnåelse',
              children: {
                delivery: { element: <Delivery />, label: 'Levere måltall' },
                update: { element: <Update />, label: 'Oppdatere måloppnåelse' },
                srl: { element: <Srl />, label: 'Srl-skala' },
                eu: { element: <EuGoals />, label: 'Miljømål i taksonomien' },
              },
            },
            ideasAndCompanies: {
              element: <IdeasAndCompanies />,
              label: `${isBusinessGarden ? 'Målbedrifter' : 'Idéer og Bedrifter'}`,
              children: {
                customerSatisfaction: { element: <CustomerSatisfaction />, label: 'Levere måltall' },
                esa: { element: <Esa />, label: 'ESA-Rapportering' },
                revertPhase: { element: <RevertPhase />, label: 'Angre faseendring' },
                batchClose: { element: <BatchClose />, label: 'Avslutte flere idéer' },
                reOpen: {
                  element: <Reopen />,
                  label: `${isBusinessGarden ? 'Gjenåpne avsluttede målbedrifter' : 'Gjenåpne avsluttede idéer og bedrifter'}`,
                },
              },
            },
          },
        },
        contactUs: {
          element: <ContactUs />,
          children: { test: { element: <ContactUs />, label: 'Kontakt oss' } },
        },
      },
    },
  };
};

const getLabel = (pathToLabel: string, isBusinessGarden: boolean) => {
  const segments = pathToLabel.split('/');
  let label = '';
  const routes = getSideMenuRoutes(isBusinessGarden);
  switch (segments.length - 1) {
    case 0:
      label = routes[segments[0]].label;
      break;
    case 1:
      label = routes[segments[0]].children[segments[1]].label;
      break;
    case 2:
      label = routes[segments[0]].children[segments[1]].children[segments[2]].label;
      break;
    default:
      label = routes[segments[0]].label;
      break;
  }
  return label;
};

export const renderPage = (path: SidemenuPaths) => {
  const routeSegments = path.split('/');
  const routes = getSideMenuRoutes();
  switch (routeSegments.length - 1) {
    case 0:
      return routes[routeSegments[0]].element;
    case 1:
      return routes[routeSegments[0]].children[routeSegments[1]].element;
    case 2:
      return routes[routeSegments[0]].children[routeSegments[1]].children[routeSegments[2]].element;
    case 3:
      return routes[routeSegments[0]].children[routeSegments[1]].children[routeSegments[2]].children[
        routeSegments[3]
      ].element;
    default:
      return routes[routeSegments[0]].element;
  }
};
