import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { createColumnHelper, Row } from '@tanstack/react-table';

import { truncate } from 'src/utils/string';
import { formatDateToNOString } from 'src/utils/FormatValue';

import { OneWayInListDto } from '../../types/oneWayInList';

import OneWayInOpportunityStatusTag from '../components/OneWayInOpportunityStatusTag';
import { ONE_WAY_IN_DETAILS } from '../../opportunity-details/routes';
import useGeoNorge from '../../hooks/use-geonorge';
import { translateStatusTag } from '../helpers/statusTags';
import { FaIcon, FaIcons } from '@in/component-library';
import { OneWayInStatus } from 'src/api/v2';

const columnHelper = createColumnHelper<OneWayInListDto>();

export const useOpportunityTableColumns = () => {
  const { getMunicipalityNameByNumber } = useGeoNorge();

  return useMemo(() => {
    return [
      columnHelper.accessor('title', {
        id: 'title',
        minSize: 400,
        cell: ({
          getValue,
          row: {
            original: { oneWayInId },
          },
        }) => {
          return (
            <Link aria-label={getValue()} to={ONE_WAY_IN_DETAILS.BASE(oneWayInId)}>
              {truncate(getValue(), 60)}
            </Link>
          );
        },
      }),
      columnHelper.accessor((row) => translateStatusTag(row.status?.status), {
        id: 'status',
        maxSize: 100,
        sortingFn: statusSort,
        cell: (info) => <OneWayInOpportunityStatusTag status={info.row.original.status?.status} />,
      }),
      columnHelper.accessor((row) => (row.submittedOn ? new Date(row.submittedOn) : null), {
        id: 'submittedDate',
        minSize: 200,
        cell: (info) => {
          const infoValue = info ? info.getValue() : null;
          return infoValue ? formatDateToNOString(infoValue, false, '{date}.{month}.{year}', true) : '';
        },

        sortingFn: 'datetime',
      }),
      columnHelper.accessor((row) => (row.lastChangedOn ? new Date(row.lastChangedOn) : null), {
        id: 'lastChangedDate',
        maxSize: 150,
        cell: (info) => {
          const infoValue = info ? info.getValue() : null;
          return infoValue ? formatDateToNOString(infoValue, false, '{date}.{month}.{year}', true) : '';
        },
        sortingFn: 'datetime',
      }),
      columnHelper.accessor((row) => getMunicipalityNameByNumber(row.municipality), {
        id: 'municipality',
        filterFn: 'includesString',
        maxSize: 130,
      }),
      columnHelper.accessor('customerName', {
        id: 'customerName',
        minSize: 200,
      }),
      columnHelper.accessor('organizationName', {
        id: 'organizationName',
        cell: (info) => {
          const organizationName = info.getValue();
          return organizationName ? (
            <>
              <FaIcon name={FaIcons.BuildingLight} /> {organizationName}
            </>
          ) : (
            ''
          );
        },
      }),
    ];
  }, [getMunicipalityNameByNumber]);
};

const statusSort = (rowA: Row<OneWayInListDto>, rowB: Row<OneWayInListDto>) => {
  const getSortValue = (row: Row<OneWayInListDto>) => {
    switch (row.original.status?.status) {
      case OneWayInStatus.New:
        return 999;
      case OneWayInStatus.Received:
        return 999;
      case OneWayInStatus.Active:
        return 30;
      case OneWayInStatus.Opened:
        return 30;
      case OneWayInStatus.ReOpened:
        return 30;
      case OneWayInStatus.Shared:
        return 30;
      case OneWayInStatus.FollowUpLater:
        return 20;
      case OneWayInStatus.Closed:
        return 10;
      case OneWayInStatus.Forwarded:
        return 10;
      default:
        return 0;
    }
  };
  return getSortValue(rowA) - getSortValue(rowB);
};
