import React from 'react';
import { useTranslation } from 'react-i18next';
import IconParagraph from '../IconParagraph';
import { FaIcons } from '@in/component-library';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/navigation';

const Esa = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <div className="stack">
      <h3 className="margin-bottom--3">{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa')}</h3>
      <IconParagraph
        faIcon={FaIcons.PlusLight}
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa.Add')}
      >
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa.Add.Text')}</p>
      </IconParagraph>
      <IconParagraph
        faIcon={FaIcons.EditLight}
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa.Edit')}
      >
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa.Edit.Text')}</p>
      </IconParagraph>
      <IconParagraph
        faIcon={FaIcons.ListLight}
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa.Use')}
      >
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa.Use.Text')}</p>
      </IconParagraph>
      <Link className="display--block" to={ROUTES.SIVA.REPORTING.FUNDS.BASE}>
        {tHelpAndSupport('commonQuestions.IdeasAndCompanies.Esa.Link')}
      </Link>
    </div>
  );
};

export default Esa;
