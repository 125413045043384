import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SivaClusterDetailsService } from 'src/api/v2';
import { SIVA_CLUSTER_DETAILS_KEY } from 'src/constants/hook-keys';

const useSivaClusterDetails = (year: number, getWithTargetNumbers = false) => {
  const [service] = useState(new SivaClusterDetailsService());

  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery(
    [SIVA_CLUSTER_DETAILS_KEY, year, getWithTargetNumbers],
    () => service.getDetails(year, getWithTargetNumbers),
    {
      enabled: !!year,
    },
  );

  const goals = useMemo(() => {
    return data?.goalAccomplishments || [];
  }, [data]);

  const goalStatus = useMemo(() => {
    return data?.goalsStatus;
  }, [data]);

  const reportingDoneMutation = useMutation(
    ({ year, reportingDone }: { year: number; reportingDone?: boolean }) => {
      queryClient.setQueryData([SIVA_CLUSTER_DETAILS_KEY, year, getWithTargetNumbers], {
        ...data,
        reportingDone: reportingDone,
      });
      return service.reportingDone(year, reportingDone);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([SIVA_CLUSTER_DETAILS_KEY, year, getWithTargetNumbers]);
      },
    },
  );

  return {
    sivaClusterDetails: data,
    goals,
    goalStatus,
    reportingDoneMutation,

    ...rest,
  };
};

export default useSivaClusterDetails;
