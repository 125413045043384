import React, { useContext } from 'react';

import '../styles/HelpAndSupportMenu.scss';
import { useTranslation } from 'react-i18next';

import { useOnboarding } from '../hooks.tsx/use-onboarding';
import SideMenuNavButton from './drawer-sidemenu/SideMenuNavButton';
import { SideMenuSubpageContext } from '../constants/Context';

const HelpAndSupportMenu = () => {
  const { onboardingPackets } = useOnboarding();
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  const { setPage } = useContext(SideMenuSubpageContext);
  if (!setPage) return <></>;

  return (
    <div>
      {onboardingPackets.length > 0 && (
        <SideMenuNavButton onClick={() => setPage('index/stepConfirmation')}>
          {tHelpAndSupport('helpAndSupport.tab.Startup.Helptext')}
        </SideMenuNavButton>
      )}
      <SideMenuNavButton onClick={() => setPage('index/commonQuestions')}>
        {tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}
      </SideMenuNavButton>
      <SideMenuNavButton onClick={() => setPage('index/contactUs')}>
        {tHelpAndSupport('helpAndSupport.tab.ContactUs')}
      </SideMenuNavButton>
    </div>
  );
};

export default HelpAndSupportMenu;
