import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import en from './en';
import nb from './nb';
import nn from './nn';

export const defaultNS = 'common';
//Creating object with the variables of imported translation files
export const resources = {
  nb,
  nn,
  en,
} as const;

// i18N Initialization
i18n.use(initReactI18next).init({
  resources,

  lng: (localStorage.getItem('lang') || 'nb').replaceAll('"', ''),
  fallbackLng: 'nb',
  ns: [
    'accountProfile',
    'buttons',
    'cluster',
    'clusterOperation',
    'common',
    'error',
    'backendErrors',
    'events',
    'oneWayIn',
    'pageTitles',
    'projects',
    'projectTypes',
    'projectCategories',
    'reports',
    'resourceBase',
    'selfEffort',
    'accountSettings',
    'tableTitles',
    'sidenav',
    'financing',
    'changelog',
    'siva',
    'clusterStatus',
    'onboarding',
  ],
  defaultNS,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
