import React, { useState, useMemo } from 'react';
import { Button, Link as ClLink, Tabs } from '@in/component-library';
import '../styles/OneWayInStyle.scss';
import { Link } from 'react-router-dom';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';
import { ONE_WAY_IN } from '..';
import { useCluster } from '../../cluster';
import useDocumentTitle from 'src/hooks/use-document-title';
import useOpportunityList from '../opportunity-table/hooks/use-opportunity-table-list';
import { useSupportEmail } from '../../../hooks/use-support-email';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import { FaIcon, Notification } from '@in/component-library';
import OneWayInOpportunityTable from '../opportunity-table/components/OneWayInOpportunityTable';
import '../styles/OneWayInStyle.scss';
import useOneWayInList from '../hooks/use-one-way-in-list';
import { useLocalStorage } from 'usehooks-ts';
import { DataTableV3LocalStoragePrefixes } from 'src/components/DataTableV3/constants/localStorage';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import { useAuth } from 'src/features/authorization';
import CreateLinkToOpportunityForm from '../components/create-link-form/CreateLinkToOpportunityForm';
import useSiteimprove from 'src/hooks/use-siteimprove';
import { OneWayInStatus } from 'src/api/v2';
import { useSearchParams } from 'react-router-dom';
import { OneWayInListDto } from '../types/oneWayInList';
import BatchArchivingModal from '../components/batch-archiving/BatchArchivingModal';

export enum TabNames {
  Open = 'open',
  Archive = 'archive',
}

const OneWayInOpportunities: React.FC = () => {
  const { cluster } = useCluster();
  const siteimprove = useSiteimprove();
  const { email, mailto } = useSupportEmail();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [localStorageTop] = useLocalStorage(
    `${DataTableV3LocalStoragePrefixes.pageSize}${DataTableV3Ids.OneWayIn.Opportunities}`,
    25,
  );
  const { checkAccess } = useAuth();
  const [top, setTop] = React.useState<number>(localStorageTop);

  const { oneWayInAllSavedFormsQuery } = useOneWayInList();

  const { opportunityList } = useOpportunityList({ formList: oneWayInAllSavedFormsQuery.data! });

  const { documentTitle } = useDocumentTitle(tOneWayIn('opportunities'));

  const hasCounties = !!cluster?.oneWayInCounties?.length;

  const hasOpportunities = oneWayInAllSavedFormsQuery.data
    ? oneWayInAllSavedFormsQuery.data.length > 0
    : false;
  const isLoading = oneWayInAllSavedFormsQuery.isLoading;

  const [showModal, setShowModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRows, setSelectedRows] = useState<OneWayInListDto[]>([]);
  const [showDialog, setShowDialog] = useState(false);

  const filteredOpportunities = useMemo(() => {
    const archivedStatuses = [OneWayInStatus.Closed, OneWayInStatus.Forwarded];
    const openOpportunities = opportunityList.filter(
      (op) =>
        (op.status?.status !== undefined && !archivedStatuses.includes(op.status?.status)) ||
        op.status?.status === undefined,
    );
    const archivedOpportunities = opportunityList.filter(
      (op) => op.status?.status !== undefined && archivedStatuses.includes(op.status?.status),
    );
    return {
      openOpportunities,
      archivedOpportunities,
    };
  }, [opportunityList]);

  const tabs = [
    {
      'tab-name': TabNames.Open,
      'aria-label': tOneWayIn('tabs.open'),
      'aria-selected': searchParams.get('activeTab') !== 'archive',
    },
    {
      'tab-name': TabNames.Archive,
      'aria-label': tOneWayIn('tabs.archive'),
      'aria-selected': searchParams.get('activeTab') === 'archive',
    },
  ];

  return (
    <>
      <DocumentTitle title={documentTitle}>
        <main className="full-width-layout">
          {checkAccess('FF_Experiment').read && (
            <Notification className="opportunity-banner" fullWidth type="info">
              <p>{tOneWayIn('addOpportunities.banner')}</p>
              <br />
              <Button
                onClick={() => {
                  siteimprove.log('OPPORTUNITY_ADD', 'OPEN');
                  setShowModal(true);
                }}
              >
                {tOneWayIn('addOpportunities.cta')}
              </Button>
            </Notification>
          )}
          <h1>{tOneWayIn('opportunities')}</h1>
          {!isLoading && !hasOpportunities && (
            <div className="margin-top--4">
              <FaIcon icon={icon({ prefix: 'fal', iconName: 'folder-open' })} size={'xl'} />
              <h2 className={'margin-top--3'}>{tOneWayIn('noOpportunitiesFoundTitle')}</h2>
              <p>{tOneWayIn('noOpportunitiesFoundDescription')}</p>

              <p>
                <Trans
                  t={tOneWayIn}
                  i18nKey="noOpportunitiesFoundContactInfo"
                  values={{ email: 'support@enveiinn.no' }}
                  components={{ '1': <ClLink href={mailto}>{email}</ClLink> }}
                >
                  Hvis du har spørsmål eller innspill så ta gjerne kontakt på support@enveiinn.no
                </Trans>
              </p>
            </div>
          )}

          {isLoading && <LoadingPage />}
          {!isLoading && oneWayInAllSavedFormsQuery.isError && (
            <Notification type="error" wrapperRole="alert">
              {tOneWayIn('errorCouldNotGetAllForms')}
            </Notification>
          )}

          <Tabs
            className="margin-top--6"
            setActiveTab={(tabIndex: number) => {
              if (tabs[tabIndex]['tab-name'] === TabNames.Archive)
                setSearchParams('activeTab=archive', { replace: true });
              else setSearchParams(undefined, { replace: true });
            }}
            tabs={tabs}
          />

          <OneWayInOpportunityTable
            isLoading={isLoading}
            opportunities={
              searchParams.get('activeTab') === 'archive'
                ? filteredOpportunities.archivedOpportunities
                : filteredOpportunities.openOpportunities
            }
            paginationListeners={{
              onSetPageSize: (pageSize) => setTop(pageSize),
            }}
            pageSize={top}
            overridePaginationTotalNumber={
              searchParams.get('activeTab') === 'archive'
                ? filteredOpportunities.archivedOpportunities.length
                : filteredOpportunities.openOpportunities.length
            }
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setShowDialog={setShowDialog}
          />
          <BatchArchivingModal
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            selectedRows={selectedRows}
          />
          {hasCounties && (
            <div className="margin-top--8">
              <p>
                {tOneWayIn('countyWarning')}{' '}
                <Link className="link--underline" to={ONE_WAY_IN.COUNTY}>
                  {tOneWayIn('goToCountyView')}
                </Link>
                .
              </p>
            </div>
          )}
          <CreateLinkToOpportunityForm
            titleHeadingLevel={2}
            title={tOneWayIn('addOpportunities.header')}
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        </main>
      </DocumentTitle>
    </>
  );
};

export default OneWayInOpportunities;
