import React from 'react';
import { ModalActionButtonContainer, Button, Theme } from '@in/component-library';
import { SivaContractDetailDto } from 'src/api/v2';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useCompanyContract from '../hooks/use-company-contract';
import { useNavigate } from 'react-router-dom';
import { IDEAS_AND_COMPANIES } from 'src/features/siva/routes';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  contract: SivaContractDetailDto;
  isActive: boolean;
  deactivate: () => void;
};

const DeleteContractdialog: React.FC<Props> = ({ title, contract, isActive, deactivate }) => {
  const { deleteSivaContractMutation } = useCompanyContract(contract.numericId);

  const { t: tCommon } = useTranslation('common');
  const { t: tSiva } = useTranslation('siva');
  const { t: tError } = useTranslation('error');

  const navigate = useNavigate();

  const handleDelete = () => {
    deleteSivaContractMutation.mutateAsync(contract.numericId).then(() => {
      deactivate();
      navigate(IDEAS_AND_COMPANIES.BASE);
    });
  };

  return (
    <DialogContainer title={title} show={isActive} onClose={deactivate}>
      <p>{tSiva('ideasAndCompanies.delete.confirm', { contract: contract.name })}</p>
      <p>{tCommon('cannotBeUndone')}</p>
      <ModalActionButtonContainer>
        <Button
          onClick={() => handleDelete()}
          theme={Theme.Danger}
          isLoading={deleteSivaContractMutation.isLoading}
          disabled={deleteSivaContractMutation.isLoading}
        >
          {tCommon('delete')}
        </Button>
        <Button theme={Theme.Neutral} onClick={deactivate}>
          {tCommon('cancel')}
        </Button>
      </ModalActionButtonContainer>
    </DialogContainer>
  );
};

export default DeleteContractdialog;
