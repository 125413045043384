import { Button, Stepper, Notification } from '@in/component-library';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import NavigationCheckBox from './NavigationCheckbox';
import { useParams } from 'react-router-dom';
import useSiteimprove from 'src/hooks/use-siteimprove';
import { SITEIMPROVE_DECLARATIONS } from 'src/features/tracking/constants';
import { PageParams } from '../../types/PageParams';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';

type Props = {
  step: number;
  numberOfSelectedRows: number;
  archiveReferral: boolean;
  setArchiveReferral: (value: React.SetStateAction<boolean>) => void;
  handleSendReferral: () => void;
  actorMessageEmpty: boolean;
  customerMessageEmpty: boolean;
  customerEmailSelection: boolean;
  handleStepChange: (prev: number, next: number) => void;
  shareOpportunityError: boolean;
};

const NavigationBar: React.FC<Props> = ({
  step,
  numberOfSelectedRows,
  archiveReferral,
  setArchiveReferral,
  handleSendReferral,
  actorMessageEmpty,
  customerMessageEmpty,
  customerEmailSelection,
  handleStepChange,
  shareOpportunityError,
}) => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { t: tCommon } = useTranslation('common');
  const { formId } = useParams<PageParams>();
  const siteimprove = useSiteimprove();
  const [notificationVisible, setNotificationVisible] = useState(false);
  const stepCount = 3;

  const NavigationPrimaryButton = () => {
    const handlePrimaryButtonClick = () => {
      if (step === 0 && numberOfSelectedRows === 0) {
        setNotificationVisible(true);
        return;
      }
      if (step === 2) {
        handleSendReferral();
        return;
      }

      siteimprove.log(
        SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
        step === 1
          ? SITEIMPROVE_DECLARATIONS.ACTIONS.GO_TO_PREVIEW
          : SITEIMPROVE_DECLARATIONS.ACTIONS.START_REFERRAL,
        formId,
      );

      handleStepChange(step, step + 1);
    };

    return (
      <Button
        disabled={step === 1 && (actorMessageEmpty || (customerEmailSelection && customerMessageEmpty))}
        onClick={handlePrimaryButtonClick}
      >
        {step === 0 && tCommon('next')}
        {step === 1 && tCommon('next')}
        {step === 2 && tOneWayIn('referral.navigationBar.step3.primaryButtonText')}
      </Button>
    );
  };

  const NavigationSecondayButton = () => {
    return (
      <TertiaryButton
        onClick={() => {
          siteimprove.log(
            SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
            step === 1
              ? SITEIMPROVE_DECLARATIONS.ACTIONS.BACK_TO_CHOOSE_ACTOR
              : SITEIMPROVE_DECLARATIONS.ACTIONS.BACK_TO_WRITE_REFERRAL,
            formId,
          );

          handleStepChange(step, step - 1);
        }}
      >
        {tCommon('previous')}
      </TertiaryButton>
    );
  };

  const LeftSection = () => {
    return (
      <div className="display--flex flex-direction--column">
        <Stepper
          className="margin-left--0 padding-bottom--0 stepper"
          currentStep={step}
          stepCount={stepCount}
        />
        <div className="display--flex flex-direction--column gap--1">
          <span className="font-weight--bold">
            {tOneWayIn('referral.navigationBar.step', { currentStep: step + 1, stepCount: stepCount })}
          </span>
          <div className="display--flex flex-direction--row padding--0 gap--1">
            {step === 0 ? (
              <span className="font-size--x-large">
                <Trans
                  t={tOneWayIn}
                  i18nKey="details.Referral.Table.RowSelectedText"
                  count={numberOfSelectedRows}
                  values={{ count: numberOfSelectedRows }}
                />
              </span>
            ) : (
              <>
                <NavigationCheckBox
                  archiveReferral={archiveReferral}
                  setArchiveReferral={setArchiveReferral}
                />
                <span className="font-size--medium">
                  {tOneWayIn('referral.navigationBar.archiveReferralOptionText')}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="navigation-bar__container">
      <div className="navigation-bar__content">
        <LeftSection />
        <div className="display--flex flex-direction--row gap--1">
          {step !== 0 && <NavigationSecondayButton />}
          <NavigationPrimaryButton />
        </div>
      </div>
      <Notification
        type="error"
        className="width--100"
        visible={shareOpportunityError || (step === 0 && notificationVisible && numberOfSelectedRows === 0)}
      >
        {step === 0 ? (
          tOneWayIn('referral.notification.step1.error')
        ) : (
          <>
            {tOneWayIn('referral.notification.step3.error')}
            <a href="maito:support@enveiinn.no">support@enveiinn.no</a>
          </>
        )}
      </Notification>
    </div>
  );
};

export default NavigationBar;
