import type { CustomerInfoDto, Address } from 'src/api/v2';

export const printCustomerInfoDtoAddress = (data: CustomerInfoDto) => {
  const print = (address: Address) => {
    let str = ``;
    if (address.addressLine1) {
      str += address.addressLine1;
    }

    if (address.addressLine2) {
      str += `, ${address.addressLine2}`;
    }

    if (address.addressLine3) {
      str += `, ${address.addressLine3}`;
    }

    if (address.zipCode) {
      str += `, ${address.zipCode}`;
    }

    if (address.city) {
      str += ` ${address.city}`;
    }

    return str;
  };

  if (data.visitorAddress) {
    return print(data.visitorAddress);
  }

  if (data.postalAddress) {
    return print(data.postalAddress);
  }

  return null;
};

export const padNumericId = (numericId: number, padValue: number = 7) => {
  if (!numericId) return '';
  return numericId.toString().padStart(padValue, '0');
};
