import React from 'react';
import { useTranslation } from 'react-i18next';
import IconParagraph from '../IconParagraph';
import { FaIcons } from '@in/component-library';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/navigation';

const BatchClose = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <div className="stack">
      <h2 className="margin-bottom--3">{tHelpAndSupport('commonQuestions.IdeasAndCompanies.BatchClose')}</h2>
      <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.BatchClose.Ingress')}</p>
      <IconParagraph
        iconDef={{ prefix: 'fal', iconName: 'square-check' }}
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.BatchClose.Choose')}
      >
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.BatchClose.Choose.Text')}</p>
      </IconParagraph>
      <IconParagraph
        faIcon={FaIcons.CheckCircleLight}
        header={tHelpAndSupport('commonQuestions.IdeasAndCompanies.BatchClose.Close')}
      >
        <p>{tHelpAndSupport('commonQuestions.IdeasAndCompanies.BatchClose.Close.Text')}</p>
      </IconParagraph>
      <Link className="display--block" to={`${ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE}?tab=ideas`}>
        {tHelpAndSupport('commonQuestions.IdeasAndCompanies.LinkIdea')}
      </Link>
    </div>
  );
};

export default BatchClose;
