import { FaIcon, FaIcons } from '@in/component-library';
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { icon } from '@fortawesome/fontawesome-svg-core';

type Props = {
  children: React.ReactNode;
  header: string;
  faIcon?: FaIcons;
  iconDef?: { prefix: IconDefinition['prefix']; iconName: string };
};

const IconParagraph = ({ children, header, faIcon, iconDef }: Props) => {
  return (
    <>
      <div className="display--flex align-items--center gap--2">
        {faIcon && <FaIcon name={faIcon} />}
        {iconDef && <FaIcon icon={icon(iconDef as IconDefinition)} />}
        <h3 className="margin-bottom--0 font-size--x-large">{header}</h3>
      </div>
      {children}
    </>
  );
};

export default IconParagraph;
