import {
  Button,
  FaIcon,
  FaIcons,
  MessageType,
  Notification,
  Spinner,
  Toast,
  Toggle,
} from '@in/component-library';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SivaContractOverviewDataTable from '../components/SivaContractOverviewDataTable';
import { useSivaContracts } from '../hooks/use-siva-contracts';
import { ROUTES } from 'src/navigation';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/features/authorization';
import { useSivaOverviewTabs } from '../hooks/use-siva-overview-tabs';
import { useSivaContractAllDataExtract } from '../hooks/use-siva-contract-data-extract';
import saveAs from 'file-saver';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import { HttpStatusCode, SivaContractOverviewDto, SivaPhase } from 'src/api/v2';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import useCompanyContract from '../company-details/hooks/use-company-contract';
import confetti from 'canvas-confetti';
import '../components/confetti.scss';
import useSivaClusterDetails from '../../reporting/hooks/useSivaClusterDetails';
import useSivaReportingYear from 'src/hooks/use-siva-reporting-year';
import OpenMenuInfoButton from 'src/features/onboarding/components/OpenMenuInfoButton/OpenMenuInfoButton';

interface ToastProps {
  visible: boolean;
  header: string;
  text: string;
  type: 'success' | 'error' | undefined;
}

const SivaContractsIndexPage: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');
  const { t: tPageTitles } = useTranslation('pageTitles');

  const navigate = useNavigate();
  const { checkAccess } = useAuth();
  const { read: isBusinessGarden } = checkAccess('UI_SivaBusinessGarden');
  const title = isBusinessGarden ? tPageTitles('targetedCompanies') : tSiva('ideasAndCompanies.title');
  const year = useSivaReportingYear();
  const {
    sivaClusterDetails,
    reportingDoneMutation,
    isLoading: isSivaClusterDetailsLoading,
  } = useSivaClusterDetails(year || 0, false);

  const { mutations, contracts, isLoading } = useSivaContracts();
  const { download } = useSivaContractAllDataExtract();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState<SivaContractOverviewDto[]>([]);

  const { availablePhasesForTab, tabElements, columnsForTab, activeTabName } =
    useSivaOverviewTabs(isBusinessGarden);
  const { revertSivaContractMutation } = useCompanyContract(undefined);

  const getToastProps = (toastType?: 'error' | 'successTerminate' | 'successReopen'): ToastProps => {
    switch (toastType) {
      case 'error':
        return {
          header: tSiva('ideasAndCompanies.generalErrorHeader'),
          text: tSiva('ideasAndCompanies.generalErrorMessage'),
          type: 'error',
          visible: true,
        };
      case 'successReopen':
        return {
          header:
            selectedRows.length > 1
              ? tSiva('ideasAndCompanies.ideasCompaniesReopenedPlural', { count: selectedRows.length })
              : tSiva('ideasAndCompanies.ideasCompaniesReopenedSingular', { count: selectedRows.length }),
          text: '',
          type: 'success',
          visible: true,
        };
      case 'successTerminate':
        return {
          header:
            selectedRows.length > 1
              ? tSiva('ideasAndCompanies.ideasTerminatedPlural.header', { count: selectedRows.length })
              : tSiva('ideasAndCompanies.ideasTerminatedSingular.header', { count: selectedRows.length }),
          text:
            selectedRows.length > 1
              ? tSiva('ideasAndCompanies.ideasTerminatedPlural.text')
              : tSiva('ideasAndCompanies.ideasTerminatedSingular.text'),
          visible: true,
          type: 'success',
        };
      default:
        return {
          header: '',
          text: '',
          visible: false,
          type: undefined,
        };
    }
  };

  const [toastProps, setToastProps] = useState<ToastProps>(getToastProps());

  const batchTerminateContracts = async () => {
    setShowDialog(false);
    setToastProps(getToastProps());
    const numericIds = selectedRows.map((row) => row.numericId);
    const phase = SivaPhase.Alumni;
    const fromDate = new Date();
    await mutations.batchUpdatePhase
      .mutateAsync({ numericIds, phase, fromDate })
      .catch(() => setToastProps(getToastProps('error')))
      .then((operationResults) => {
        if (operationResults != null && operationResults.some((or) => or.status != HttpStatusCode.OK)) {
          setToastProps(getToastProps('error'));
          return;
        }
        setToastProps(getToastProps('successTerminate'));
      });
  };

  const batchReopenContracts = async () => {
    setShowDialog(false);
    setToastProps(getToastProps());
    const numericIds = selectedRows.map((row) => row.numericId);
    const promises: Promise<void>[] = [];
    for (const numericId of numericIds) {
      promises.push(revertSivaContractMutation.mutateAsync(numericId));
    }
    Promise.all(promises).then(() => setToastProps(getToastProps('successReopen')));
  };

  const TerminateContractsDialog = () => {
    return (
      <DialogContainer
        title={tSiva('ideasAndCompanies.terminateChosenIdeas')}
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <p>
          {`${tSiva('ideasAndCompanies.wantToTerminate')} ${selectedRows.length} ${selectedRows.length > 1 ? tSiva('ideasAndCompanies.chosenIdeasPlural') : tSiva('ideasAndCompanies.chosenIdeasSingular')}`}
        </p>
        <div className="display--flex gap--1">
          <Button onClick={batchTerminateContracts}>{tSiva('ideasAndCompanies.terminate')}</Button>
          <Button variant="outlined" onClick={() => setShowDialog(false)}>
            {tCommon('cancel')}
          </Button>
        </div>
      </DialogContainer>
    );
  };

  const ReopenContractsDialog = () => {
    return (
      <DialogContainer
        title={tSiva('ideasAndCompanies.reopenChosenIdeasCompanies')}
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <p>{`${tSiva('ideasAndCompanies.wantToReopen')} ${selectedRows.length} ${selectedRows.length > 1 ? tSiva('ideasAndCompanies.chosenIdeasCompaniesPlural') : tSiva('ideasAndCompanies.chosenIdeasCompaniesSingular')}`}</p>
        <div className="display--flex gap--1">
          <Button onClick={batchReopenContracts}>
            {tSiva('ideasAndCompanies.reopenChosenIdeasCompanies')}
          </Button>
          <Button variant="outlined" onClick={() => setShowDialog(false)}>
            {tCommon('cancel')}
          </Button>
        </div>
      </DialogContainer>
    );
  };

  if (mutations.batchUpdatePhase.isLoading || revertSivaContractMutation.isLoading) {
    return (
      <main className="full-width-layout">
        <h1 className="margin-bottom--6">{title}</h1>
        <LoadingPage />
      </main>
    );
  }

  return (
    <main className="full-width-layout">
      <h1 className="margin-bottom--6">
        {title} <OpenMenuInfoButton page={'index/commonQuestions/ideasAndCompanies'} />
      </h1>
      {year != null && (
        <Notification type={MessageType.Generic} className="confetti-container">
          <h2>
            {tSiva('ideasAndCompanies.reportingDone.header', { year })}{' '}
            <OpenMenuInfoButton smallIcon page="index/commonQuestions/sivaReporting" />
          </h2>
          <p>{tSiva('ideasAndCompanies.reportingDone.text', { year })}</p>
          <canvas id="canvas"></canvas>
          {isSivaClusterDetailsLoading ? (
            <Spinner />
          ) : (
            <Toggle
              value={sivaClusterDetails?.reportingDone || false}
              labelPosition="right"
              label={
                sivaClusterDetails?.reportingDone
                  ? tSiva('ideasAndCompanies.reportingDone.finished')
                  : tSiva('ideasAndCompanies.reportingDone.notFinished')
              }
              onClick={() => {
                const newValue = !sivaClusterDetails?.reportingDone;
                if (newValue == true) {
                  const cf = confetti.create(document.getElementById('canvas'), {
                    resize: true,
                    useWorker: true,
                  });
                  cf({
                    particleCount: 500,
                    spread: 110,
                    angle: 90,
                    startVelocity: 50,
                    ticks: 1000,
                    gravity: 1,
                    drift: 0.3,
                    disableForReducedMotion: true,
                    origin: {
                      x: 0.75,
                      y: 1.5,
                    },
                    colors: ['#141528', '#2d2e6c', '#565fea', '#f0f9fa'],
                  });
                }
                reportingDoneMutation.mutateAsync({ year, reportingDone: newValue });
              }}
            />
          )}
        </Notification>
      )}

      {checkAccess('SivaCore').write && (
        <div className="display--flex gap--1">
          <Button
            type="button"
            iconName={FaIcons.PlusLight}
            onClick={() => {
              navigate(ROUTES.SIVA.IDEAS_AND_COMPANIES.NEW);
            }}
          >
            {tCommon('addNew')}
          </Button>
          <Button
            type="button"
            variant="outlined"
            iconName={FaIcons.FileDownloadLight}
            onClick={async () => {
              const file = await download.mutateAsync(false);
              saveAs(file.data, file.fileName);
            }}
          >
            {tSiva('export.ideasAndCompanies.exportTotal')}
          </Button>
        </div>
      )}

      {tabElements}
      <>
        {contracts.length === 0 && !isLoading ? (
          <div className="display--flex flex-direction--column align-items--flex-start gap--3 margin-bottom--6">
            <div className="display--flex align-items--center gap--2">
              <FaIcon className="font-size--40px" name={FaIcons.SparklesLight} />
              <FaIcon className="font-size--40px" name={FaIcons.BuildingLight} />
            </div>
            <h2 className="margin-y--0">{tSiva('ideasAndCompanies.notFound')}</h2>
          </div>
        ) : (
          <SivaContractOverviewDataTable
            availablePhases={availablePhasesForTab}
            columns={columnsForTab}
            setShowDialog={setShowDialog}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            activeTabName={activeTabName}
          />
        )}
      </>

      {activeTabName == 'ideas' && <TerminateContractsDialog />}
      {activeTabName == 'alumni' && <ReopenContractsDialog />}
      <Toast
        visible={toastProps.visible}
        header={toastProps.header}
        icon={
          <FaIcon
            className={`${toastProps.type == 'success' && 'color--in-green-200'} ${toastProps.type == 'error' && 'color--red'}`}
            name={FaIcons.InfoCircle}
          />
        }
        position="bottom-center"
        text={toastProps.text}
      />
    </main>
  );
};

export default SivaContractsIndexPage;
