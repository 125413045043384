import React from 'react';
import ActionPageDescription from '../ActionPageDescription';
import { FaIcon, FaIcons } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const Referralprocess: React.FC = () => {
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <>
      <h2 className="margin-bottom--6">{tHelpAndSupport('commonQuestions.Referralprocess')}</h2>
      <div className="display--flex flex-direction--column gap--6">
        <ActionPageDescription
          icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'list-check' }) as IconDefinition} />}
          iconName={tHelpAndSupport('commonQuestions.StepTitles.ChooseActor')}
          description={tHelpAndSupport('commonQuestions.StepDescriptions.ChooseActor')}
        />
        <ActionPageDescription
          icon={<FaIcon name={FaIcons.EnvelopeLight} />}
          iconName={tHelpAndSupport('commonQuestions.StepTitles.WriteReferral')}
          description={tHelpAndSupport('commonQuestions.StepDescriptions.WriteReferral')}
          showOptionalIndication={true}
        />
        <ActionPageDescription
          icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'paper-plane' }) as IconDefinition} />}
          iconName={tHelpAndSupport('commonQuestions.StepTitles.SendReferral')}
          description={tHelpAndSupport('commonQuestions.StepDescriptions.SendReferral')}
          showOptionalIndication={true}
        />
      </div>
    </>
  );
};

export default Referralprocess;
