import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { statusTags } from '../../constants/statusTags';
import { useOpportunityTableColumns } from '../hooks/use-opportunity-table-columns';
import { filterSelectedStatusTag } from '../helpers/statusTags';
import useMunicipality from '../hooks/use-municipality';
import useStatusTags, { StatusFilterType } from '../hooks/use-status-tags';
import useGeonorge from '../../hooks/use-geonorge';
import ResetFilterButton from 'src/components/ResetFilterButton/ResetFilterButton';
import DataTableV3, {
  DataTableV3PaginationListenersProps,
  DataTableV3Actions,
} from '../../../../components/DataTableV3';
import { OneWayInListDto } from '../../types/oneWayInList';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import { useSearchParams } from 'react-router-dom';
import FilterDateInput from 'src/components/Filters/FilterDateInput';
import QueryParamMultiSelect from 'src/components/Filters/QueryParamMultiSelect';

type OneWayInOpportunityTableTypes = {
  opportunities: OneWayInListDto[];
  paginationListeners: DataTableV3PaginationListenersProps;
  pageSize: number;
  overridePaginationTotalNumber: number;
  selectedRows: OneWayInListDto[];
  setSelectedRows: React.Dispatch<React.SetStateAction<OneWayInListDto[]>>;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
};

const queryParams = {
  fromDate: 'fromDate',
  toDate: 'toDate',
  lastChangedDate: 'lastChangedOn',
  municipality: 'municipality',
  status: 'status',
};

const OneWayInOpportunityTable: React.FC<OneWayInOpportunityTableTypes> = ({
  opportunities,
  isLoading,
  paginationListeners,
  pageSize,
  overridePaginationTotalNumber,
  selectedRows,
  setSelectedRows,
  setShowDialog,
}) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { t: tTableTitles } = useTranslation('tableTitles');

  const [searchParams, setSearchParams] = useSearchParams();
  const columns = useOpportunityTableColumns();
  const { getMunicipalityNameByNumber } = useGeonorge();

  const actions: DataTableV3Actions<OneWayInListDto> = {
    secondary: [
      {
        icon: { prefix: 'fal', iconName: 'folder-open' },
        label: tOneWayIn('batchArchive.archive'),
        onClick: () => setShowDialog(true),
        disabled: selectedRows.length === 0,
      },
    ],
  };

  const { selectedMunicipalityIds, selectedStatusIds, fromDate, toDate } = useMemo(() => {
    return {
      selectedMunicipalityIds: searchParams.get(queryParams.municipality)?.split(',') ?? [],
      selectedStatusIds: searchParams.get(queryParams.status)?.split(',') ?? [],
      fromDate: searchParams.get(queryParams.fromDate) ?? '',
      toDate: searchParams.get(queryParams.toDate) ?? '',
    };
  }, [searchParams]);

  const filtersActive = useMemo(() => {
    if (
      selectedMunicipalityIds.length > 0 ||
      selectedStatusIds.length > 0 ||
      fromDate.length > 0 ||
      toDate.length > 0
    ) {
      return true;
    }
    return false;
  }, [selectedMunicipalityIds.length, selectedStatusIds.length, fromDate.length, toDate.length]);

  const { statusOptions } = useStatusTags(selectedStatusIds);
  const { municipalityOptions } = useMunicipality(opportunities);

  const convertedMunicipalityOptions = useMemo(() => {
    return municipalityOptions.map((municipality) => ({
      text:
        getMunicipalityNameByNumber(municipality.text) ||
        tOneWayIn('opportunity.table.filter.noMunicipality'),
      value: municipality.value || '',
    }));
  }, [municipalityOptions, getMunicipalityNameByNumber, tOneWayIn]);

  const filterByMunicipality = (item: OneWayInListDto): boolean => {
    if (selectedMunicipalityIds.length === 0) return true;
    //Håndtering for når en henvendelse ikke har satt kommune
    if (!item.municipality) return selectedMunicipalityIds.includes('');
    return selectedMunicipalityIds.includes(item.municipality.toLowerCase());
  };

  const filterByStatusTags = (item: OneWayInListDto): boolean => {
    const selectedStatuses = selectedStatusIds.map((id) => id as StatusFilterType);

    if (selectedStatuses.length === 0) return true;

    const filterStatusTagValue = selectedStatuses.flatMap((selectedStatus) => statusTags[selectedStatus]);

    return filterSelectedStatusTag(item, filterStatusTagValue);
  };

  const filterByDateRange = (item: OneWayInListDto): boolean => {
    if (item?.submittedOn === undefined) return false;
    const submittedDate = new Date(item?.submittedOn);
    return (
      (fromDate ? submittedDate >= new Date(fromDate) : true) &&
      (toDate ? submittedDate <= new Date(toDate) : true)
    );
  };

  const tableData: OneWayInListDto[] = opportunities
    .filter(filterByMunicipality)
    .filter(filterByStatusTags)
    .filter(filterByDateRange);

  function handleClearFilter() {
    if (searchParams.get('activeTab') === 'archive') setSearchParams('activeTab=archive', { replace: true });
    else setSearchParams(undefined, { replace: true });
  }

  const filteredStatusOptions = useMemo(() => {
    const openStatusOptions = statusOptions.filter((so) => so.value !== 'archived');
    return { openStatusOptions };
  }, [statusOptions]);

  const filters = (
    <div className="display--flex flex-wrap--wrap align-items--center margin-bottom--6 gap--2 margin-0-on-all-children">
      <QueryParamMultiSelect
        queryStringParam={queryParams.municipality}
        options={convertedMunicipalityOptions}
        hideError
        name="municipality"
        label={tCommon('municipality')}
      />

      {searchParams.get('activeTab') !== 'archive' && (
        <QueryParamMultiSelect
          queryStringParam={queryParams.status}
          options={filteredStatusOptions.openStatusOptions}
          hideError
          name={'status'}
          label={tCommon('status')}
        />
      )}

      <div className="flex-grow--1">
        <FilterDateInput queryStringParam={queryParams.fromDate} label={tCommon('fromDate')} />
      </div>
      <div className="flex-grow--1">
        <FilterDateInput queryStringParam={queryParams.toDate} label={tCommon('toDate')} />
      </div>

      <ResetFilterButton className="margin-top--2" onClick={handleClearFilter} />
    </div>
  );

  return (
    <>
      <DataTableV3
        id={DataTableV3Ids.OneWayIn.Opportunities}
        actions={searchParams.get('activeTab') === 'archive' ? undefined : actions}
        disableSelection={searchParams.get('activeTab') === 'archive' ? true : false}
        loading={isLoading}
        customColumnFilters={filters}
        data={tableData}
        columns={columns}
        translationFn={(key: string) => tTableTitles(key as any)}
        texts={{
          globalFilterPlaceholder: tOneWayIn('opportunitySearchInputPlaceHolder'),
          rowsPerPage: tCommon('rowsPerPage'),
          of: tCommon('of'),
        }}
        sorting={[
          {
            desc: true,
            id: 'submittedDate',
          },
        ]}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        pageSize={pageSize}
        disableColumnEditor
        disableExport
        filtersActive={filtersActive}
        paginationListeners={paginationListeners}
        overridePaginationTotalNumber={overridePaginationTotalNumber}
        autoResetPageIndex={false}
        showRowsSelectedText={searchParams.get('activeTab') === 'archive' ? false : true}
        setSelectedRows={setSelectedRows}
        hideFilterText
      />
    </>
  );
};

export default OneWayInOpportunityTable;
