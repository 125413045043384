import { Card } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  actorMessage: string;
  customerMessage: string;
  referTo: string[];
  customerEmailSelection: boolean;
  formTitle: string;
  actorName: string;
  customerName: string;
  handlersName?: string;
};

const SendReferral: React.FC<Props> = ({
  actorMessage,
  customerMessage,
  referTo,
  customerEmailSelection,
  formTitle,
  actorName,
  customerName,
  handlersName,
}) => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const listFormat = new Intl.ListFormat('no-NO');
  const selectedActors = referTo.filter((name) => name !== '');
  const formattedActorList = listFormat.format(selectedActors);

  const EmailPreviewCardActor = () => {
    return (
      <Card className="message-display--card">
        <span className="margin-top--0 margin-bottom--2 font-weight--bold">
          Ny henvisning i Flerbedriftsportalen
        </span>
        <br />
        <span>
          {tOneWayIn('to')}
          {selectedActors.join(', ')}
        </span>
        <p>
          {formattedActorList}, dere har fått en ny henvisning fra {actorName}. Dette gjelder henvendelsen:{' '}
          <em>{formTitle}</em>. Logg inn på Flerbedriftsportalen for å lese henvendelsen.
        </p>
        <p className="margin-top--0">Melding fra {actorName}:</p>
        <p>{actorMessage}</p>
        {handlersName && <p>Med vennlig hilsen {handlersName}</p>}
        <p>MERK: Svar til denne e-postadressen blir ikke behandlet.</p>
      </Card>
    );
  };

  const EmailPreviewCardCustomer = () => {
    return (
      <Card className="message-display--card">
        <span className="margin-top--0 margin-bottom--2 font-weight--bold">Informasjon om henvisning</span>
        <br />
        <span>
          {tOneWayIn('to')}
          {customerName}
        </span>
        <p>
          {customerName}, din henvendelse: <em>{formTitle}</em>, er henvist til {formattedActorList}.
        </p>
        <p className="margin-top--0">Melding fra {actorName}:</p>
        <p>{customerMessage}</p>
        {handlersName && <p>Med vennlig hilsen {handlersName}</p>}
        <p>MERK: Svar til denne e-postadressen blir ikke behandlet.</p>
      </Card>
    );
  };

  return (
    <div className="display--flex flex-direction--column gap--6">
      <p className="margin-bottom--0 margin-top--0">
        {customerEmailSelection
          ? tOneWayIn('referral.sendReferral.sendReferralInfoWithCustomer')
          : tOneWayIn('referral.sendReferral.sendReferralInfo')}
      </p>
      <div>
        <h3 className="header--bold">{tOneWayIn('referral.writeReferral.toActors')}</h3>
        <EmailPreviewCardActor />
      </div>
      {customerEmailSelection && (
        <div>
          <h3 className="padding-top--3 header--bold">{tOneWayIn('referral.writeReferral.toCustomer')}</h3>
          <EmailPreviewCardCustomer />
        </div>
      )}
    </div>
  );
};

export default SendReferral;
